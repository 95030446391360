<template>
  <div class="text-left">
    <div class="bg-white p-6">
      <div class="bg-fa rounded-5 p-6">
        <div class="text-c14 font-c6">
          Commentaires
        </div>

        <div
          class=""
          v-for="(item, index) in allComment"
          :key="index"
        >
          <div class="text-c13 mt-4 text-63">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec sagittis nunc natoque turpis tempus sem quis. Vitae dictum commodo lacinia rutrum. Accumsan orci pretium egestas arcu, egestas volutpat turpis non mauris.
          </div>

          <div class="flex text-c11 mt-4">
            <div class="text-90">
              Comptabilité :    
              <span class="text-oho-blue ml-1">
                Dwayne Michael
              </span>
            </div>

            <div class="text-90 ml-10">
              Date :    
              <span class="text-oho-blue ml-1">
                12-11-2021, 15h21
              </span>
            </div>
          </div>
          
          <div
            class="h-1 bg-d w-full mt-6"
            v-if="index+1 < allComment.length"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",

  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allComment: ['o', 'p']
    }
  },

  computed: {
    
  },

  created () {
   
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
</style>
