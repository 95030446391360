<template>
  <div class="text-left bg-white p-6 pt-10 pb-14">
    <div
      class="flex pl-6 pr-6 text-c5 text-c14"
      @click="activePosition = -1"
    >
      <div class="w-1/3">
        ARTICLES
      </div>

      <div class="w-2/3 flex">
        <div class="w-1/5 ml-4">
          QTE
        </div>
        <div class="w-1/5 ml-4">
          REÇU
        </div>
        <div class="w-1/4 ml-4">
          MANQUANTS
        </div>
        <div class="w-1/4 ml-4">
          PRIX
        </div>
        <div class="w-1/4 ml-4">
          TOTAL 
        </div>
      </div>
    </div>

    <div class="h-1 bg-d w-full mt-4" />

    <div class="">
      <div
        class="w-full flex p-6"
        v-for="(item, index) in sendArticle"
        :key="index"
      >
        <div class="w-1/3">
          <div class="text-c14 font-c5">
            {{ item.marque }} {{ item.reference }}
          </div>
        </div>

        <div class="w-2/3 flex">
          <div class="w-1/5 ml-4">
            {{ item.qte }}
          </div>

          <div class="w-1/5 ml-4">
            ----
          </div>

          <div class="w-1/4 ml-4">
            ----
          </div>

          <div class="w-1/4 ml-4">
            {{ item.price }}
          </div>

          <div class="w-1/4 ml-4">
            {{ item.price * item.qte }} 
          </div>
        </div>
      </div>
    </div>

    <div class="h-1 bg-d w-full mt-10" />

    

    <div
      class="mt-6 text-right"
      v-if="sendArticle.length > 0"
    >
      <div class="flex justify-end">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c12 w-2/5 text-90">
            Transport fournisseur :
          </div>
          
          <div class="w-2/5 ml-4 pr-6">
            314 $
          </div>
        </div> 
      </div> 

      <div class="flex justify-end mt-6">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c12 w-2/5 text-90">
            Qté total :
          </div>

          <div class="w-2/5 ml-4 pr-6">
            1069
          </div>
        </div> 
      </div> 

      <div class="flex justify-end mt-6">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c12 w-2/5 text-90">
            Qté reçue :
          </div>

          <div class="w-2/5 ml-4 pr-6">
            ----
          </div>
        </div> 
      </div> 

      <div class="flex justify-end mt-6">
        <div class="w-1/3 flex items-center justify-end">
          <div class="text-c11 w-2/5 font-c6">
            Total commande : 
          </div>

          <div class="w-2/5 ml-4 pr-6 font-c6">
            628 000 000 $
          </div>
        </div> 
      </div> 
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",

  components: {
  },

  props: {
    donnes: {
      type: Object,
      default: null
    },

    allArticle: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      icons: {
      },
      devis: null,
      activePosition: -1
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendArticle: function () {
      return this.allArticle
    }
  },

  created () {
  },

  methods: {
    retourAdd () {
      this.$emit('add', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.tete{
  color: #434343;
  background: linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%);
  border-radius: 10px;
  border-top: 5px solid #434343;
}

</style>
