<template>
  <div class="p-10 pr-16">
    <popupCharge
      :activation="charge"
      v-if="charge"
    />

    <div>
      <div class="flex items-center">
        <div class="w-3/6 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            INV/OHO-0011
          </div>
        </div>
      </div>

      <div class="mt-6">
        <div class="w-full">
          <tete />

          <commentaire class="mt-6" />
          <articleListe :all-article="allArticle" class="mt-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import tete from '../../component/cdevs/detailFacture/header'
import commentaire from '../../component/cdevs/detailFacture/commentaire'
import articleListe from '../../component/cdevs/detailFacture/articleListe.vue'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    tete,
    popupCharge,
    commentaire,
    articleListe
  },

  data () {
    return {
      icons: {
        back
      },
      charge: false,
      allArticle: [
        {
          reference: '238Re',
          qte: 4,
          price: 200,
          marque: 'Dolces Gabana'
        },

        {
          reference: '108Re',
          qte: 2,
          price: 127,
          marque: 'LV'
        }
      ]
    }
  },

  created () {
  },

  methods: {

    retourBack () {
      this.$router.push('/facture')
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
</style>
